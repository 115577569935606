// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Custom Skeleton Line Height and Margin */
.header{
padding: 10px;
}

.valid_msg {
border: 1px solid var(--ion-color-success);
color: var(--ion-color-success) !important;
padding:10px;
max-width:350px;
border-radius:10px;
}

.h_title {
border: 1px solid var(--ion-color-medium);
color: var(--ion-color-medium) !important;
padding:10px;
border-radius:10px;
}

.text08 {
font-size:0.7em;
}

.left-margin{ 
margin:10px;
}

.dark {
color:#666 !important;
}

.red {
color: #FF0000 !important;
}

.textstrong {
font-size:0.7em;
}

.sub-item {
    font-size: small !important;
	color: #777777 !important;
}

.small-item{
color: #111111 !important;
font-size: small !important;
font-weight: bold;
}

.bordered {
}

.bglight{
  padding:10px;
}

ion-item {
    --ion-item-background: transparent;
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered {
  text-align: center;
}

.width100 {
width:100%;
}

.bold { 
font-weight:bold !important; 
}

.rowofnumber {
padding:10px !important;
}

ion-menu{
  --width: 234px;
}

.dotWin {
  height: 42px;
  width: 42px;
  background-color: #ffa902;
  border: 1px solid #ffa902;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMac {
  height: 42px;
  width: 42px;
  background-color: #0b9658;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWingh {
  height: 42px;
  width: 42px;
  background-color: #eb012e;
  border: 1px solid #eb012e;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacgh {
  height: 42px;
  width: 42px;
  background-color: #000000;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWinng {
  height: 42px;
  width: 42px;
  background-color: #259455;
  border: 1px solid #259455;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacng {
  height: 42px;
  width: 42px;
  background-color: #000000;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWinfr {
  height: 42px;
  width: 42px;
  background-color: #c10040;
  border: 1px solid #c10040;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacfr {
  height: 42px;
  width: 42px;
  background-color: #004775;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWinbn {
  height: 42px;
  width: 42px;
  border: 2px solid #118450;
  color: #111111;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacbn {
  height: 42px;
  width: 42px;
  color: #111111;
  border: 2px solid #f4cb13;
  color:#111;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWintg {
  height: 42px;
  width: 42px;
  background-color: #e51330;
  border: 1px solid #e51330;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMactg {
  height: 42px;
  width: 42px;
  background-color: #118450;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWincm {
  height: 42px;
  width: 42px;
  background-color: #017258;
  border: 1px solid #017258;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMaccm {
  height: 42px;
  width: 42px;
  background-color: #ce0f25;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWingn {
  height: 42px;
  width: 42px;
  background-color: #ce0f25;
  border: 1px solid #ce0f25;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacgn {
  height: 42px;
  width: 42px;
  background-color: #000000;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWincg {
  height: 42px;
  width: 42px;
  background-color: #fbde4a;
  border: 1px solid #fbde4a;
  color: #000000;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMaccg {
  height: 42px;
  width: 42px;
  background-color: #d2231e;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.dotWinaf {
  height: 42px;
  width: 42px;
  background-color: #002292;
  border: 1px solid #002292;
  color: #FFFFFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
} 

.dotMacaf { 
  height: 42px;
  width: 42px;
  background-color: #010001;
  color:#FFF;
  border-radius: 50%;
  display: inline-block;
  text-align:center;
  margin:5px;
  font-size:25px;
}

.color-greenaf{ 
color: #002292 !important;
}

.color-redaf{
color: #010001 !important;
}

.color-greencg{ 
color: #fbde4a !important;
}

.color-redcg{
color: #d2231e !important;
}

.color-greengn{
color: #ce0f25 !important;
}

.color-redgn{
color: #000000 !important;
}

.color-greencm{
color: #017258 !important;
}

.color-redcm{
color: #ce0f25 !important;
}


.color-orange{
color:orange !important;
}

.color-green{
color: #ffa902 !important;
}

.color-red{
color: #0b9658 !important;
}

.color-greengh{
color: #eb012e !important;
}

.color-redgh{
color: #000000 !important;
}

.color-greenbn{
color: #118450 !important;
}

.color-redbn{
color: #f4cb13 !important;
}

.color-greentg{
color: #e51330 !important;
}

.color-redtg{
color: #118450 !important;
}

.color-greenng{
color: #259455 !important;
}

.color-redng{
color: #000000 !important;
}

.color-greenfr{
color: #c10040 !important;
}

.color-redfr{
color: #004775 !important;
}

.matrice{
color:#0b9658; 
font-weight:bold !important;
font-size:1.3em !important;
}

.even{
  background: #F8F8F8;
}

.odd{
  background: #EEEEEE;
  border-radius:10px;
  max-width:200px;
}

.bgray {
   background: #F5F5F5 !important;
   width:100%;
   padding:10px;
   height:100vh;
}

.bgbanka {
   background: #F5F5F5 !important;
   width:100%;
   padding:10px;
   height:100vh;
}

.c-h{
width:100%;
background:#FFF;
margin-bottom:1em;
border-radius:10px;
}

.c-v{
margin:1em;
border-radius:10px;
}

ion-slide {
background-color: #f8f8f8;
}

ion-slide img {
height: auto !important;
width: 100% !important;
}

.ion-icon {
font-size: 70px !important; 
color:#333;
}

.small {
font-size: 0.8em !important; 
color:#333;
}

.ion-img {
width: 100% !important;
max-width: 90px !important; 
}

.custom {
border-radius:10px;
border: 1px solid #CCC;
background:#EEEEEE;
margin-top:10px;
}

.maxwidth350{
max-width:350px;
}
